import React, { Component,useState  } from 'react'
import Popup from '../components/Popup'

export class Footer extends Component{
    constructor(props) {
        super(props);  
        this.state = {
            isOpen : false
        };
    }   
   
     
    handleLogin(e) {
        e.preventDefault(); 
        const data = new FormData(e.target);
        alert(data); 
    }      
    render () {
        return (  

            <div>
                {/*Site Footer One Start*/}
                <footer
                    className="site-footer"
                    style={{
                        backgroundImage: "url(assets/images/backgrounds/site_footer_bg.jpg)",
                    }}
                >
                    <div className="container">
                        <div className="site-footer__one-top">
                            <div className="row">
                                <div
                                    className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                                    data-wow-delay="00ms"
                                >
                                    <div className="footer-widget__column footer-widget__about">
                                        <div className="footer-widget__logo">
                                            <a href="/">
                                                <img src="assets/images/resources/footer-logo.png" alt />
                                            </a>
                                        </div>
                                        <p className="footer-widget__text">
                                            Karunyanidhi is the project launched by the diocese as part of
                                            its social responsibility for charitable activities among the
                                            sick people.
                </p>
                                        <div className="site-footer__social">
                                            <a href="#" className="clr-fb">
                                                <i className="fab fa-facebook-square" />
                                            </a>
                                            <a href="#" className="clr-dri">
                                                <i className="fab fa-google" />
                                            </a>
                                            <a href="#" className="clr-ins">
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                                    data-wow-delay="200ms"
                                >
                                    <div className="footer-widget__column footer-widget__explore clearfix">
                                        <h3 className="footer-widget__title">Explore</h3>
                                        <ul className="footer-widget__explore-list list-unstyled">
                                            <li>
                                                <a href="/donate">Donate</a>
                                            </li>
                                            <li>
                                                <a href="#">Campaigns</a>
                                            </li>
                                            <li>
                                                <a href="#">Fundraise</a>
                                            </li>
                                            <li>
                                                <a href="#">Volunteers</a>
                                            </li>
                                            <li>
                                                <a href="#">Sponsors</a>
                                            </li>
                                            <li>
                                                <a href="#" onClick={() => this.setState({ isOpen: !this.state.isOpen })}>Admin Login</a>
                                                {this.state.isOpen && <Popup
      content={<>
        <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h4 className="modal-title w-100 font-weight-bold">Sign in</h4>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={this.handleLogin} >
          <div className="modal-body mx-3">          
            <div className="md-form mb-5">
              <i className="fas fa-envelope prefix grey-text" />              
              <input type="email" id="defaultForm-email" className="form-control validate" name="Email" required />
              <label data-error="wrong" data-success="right" htmlFor="defaultForm-email">Your email</label>
            </div>
            <div className="md-form mb-4">
              <i className="fas fa-lock prefix grey-text" />
              <input type="password" id="defaultForm-pass" className="form-control validate" name="Password" required />
              <label data-error="wrong" data-success="right" htmlFor="defaultForm-pass">Your password</label>
            </div>
          </div>          
          <div className="modal-footer d-flex justify-content-center">
            <button className="btn btn-default" type="submit">Login</button>
          </div>
          </form>
        </div>
      </div>
      </>}
      handleClose={() => this.setState({ isOpen: !this.state.isOpen })}
    />}
                                            </li>
                                            
    
    
                                        </ul>
                                        
                                    </div>
                                </div>
                                <div
                                    className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                                    data-wow-delay="300ms"
                                >
                                    <div className="footer-widget__column footer-widget__contact">
                                        <h3 className="footer-widget__title">Contact</h3>
                                        <ul className="list-unstyled footer-widget__contact-list">
                                            <li>
                                                <div className="icon">
                                                    <i className="fas fa-phone-square-alt" />
                                                </div>
                                                <div className="text">
                                                    <p>
                                                    <a href="tel:+91 6305144737">+91 6305144737</a>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <i className="fas fa-envelope" />
                                                </div>
                                                <div className="text">
                                                    <p>
                                                    <a href="mailto:karunyanidhi.adb@gmail.com">
                                                        karunyanidhi.org@gmail.com
                    </a>
                                                    </p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="icon">
                                                    <i className="fas fa-map-marker-alt" />
                                                </div>
                                                <div className="text">
                                                    <p>Bishop's House
Hanuman Nagar
Mancherial PO 504208
Mancherial dt.
Telangana State</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div
                                    className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
                                    data-wow-delay="400ms"
                                >
                                    <div className="footer-widget__column footer-widget__support">
                                        <h3 className="footer-widget__title">Support</h3>
                                        <p className="footer-widget__support-text">
                                        Join the efforts of Adilabad Diocese in ameliorating the miseries of our Telugu brethren
                </p>
                                        <div className="footer-widget__support-btn">
                                            <a href="/donate">
                                                <i className="fas fa-heart" />
                    Donate
                  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                {/*Site Footer One End*/}
                {/*Site Footer Bottom Start*/}
                <div className="site-footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="site-footer-bottom__inner">
                                    <div className="site-footer-bottom__left">
                                        <p>
                                        © Copyright 2024 by <a href="#">Karunyanidhi</a>
                                        </p>
                                    </div>
                                    <ul className="site-footer-bottom__menu list-unstyled">
                                        <li>
                                            <a href="#">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <span>/</span>
                                        </li>
                                        <li>
                                            <a href="#">Terms of Condition</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Site Footer Bottom End*/}
            </div>
        )
      }
    }

export default Footer