import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'Success || Karunyanidhi'
export class Paymentsuccess extends Component {
    static displayName = Paymentsuccess.name;
    
    render() {
        return (
            <div>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>               
                <section className="payment-page">
                    <div className="container">
                    <div class="row">
      <div class="col-md-6 mx-auto mt-5">
         <div class="payment">
            <div class="payment_header">
               <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
            </div>
            <div class="content">
               <h1>Payment Success !</h1>
               <p>Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print, graphic or web designs. </p>
               <a href="/">Go to Home</a>
            </div>
            
         </div>
      </div>
   </div>
                        </div>
                </section>
                {/*Donate Page End*/}
                </div>
        );
    }
}

export default Paymentsuccess