import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

const TITLE = 'Donate || Karunyanidhi'
export class Donate extends Component {
    static displayName = Donate.name;
    render() {
        return (
            <div>
                <div id="loader" style={{display:"none"}}></div>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>
                {/*Page Header Start*/}
                <section
                    className="page-header"
                    style={{
                        backgroundImage: "url(assets/images/backgrounds/page-header-bg.jpg)",
                    }}
                >
                    <div className="container">
                        <div className="page-header__inner">
                            <h2>Donate</h2>
                            <ul className="thm-breadcrumb list-unstyled">
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <li>
                                    <span>/</span>
                                </li>
                                <li>Donate</li>
                            </ul>
                        </div>
                    </div>
                </section>
                {/*Page Header End*/}
                {/*donate Page Start*/}

                <section className="bank-account-sec">
        <div className="container">
          <div className="block-title text-center">
            <h4>Lorem ipsum dolor.</h4>
            <h2>Lorem ipsum dolor sit amet</h2>
          </div>
          <div className="bank-account-wrapper"> 
            <div className="text-area">
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ab maiores voluptatem, minima quisquam in nesciunt omnis 
                repudiandae laudantium veritatis sapiente. Maxime mollitia ut, asperiores optio corporis ratione possimus autem dolor.</p>
            </div>
            <div className="img-area wow slideInLeft" data-wow-delay="200ms">
              <div className="imgs image-one position-absolute">
                <img src="./assets/images/blog/news_one_img_1.jpg" alt="image" />
              </div>
              <div className="imgs image-two position-absolute">
                <img src="./assets/images/blog/news_one_img_2.jpg" alt="image" />
              </div>
              <div className="imgs image-three position-absolute">
                <img src="./assets/images/blog/news_one_img_3.jpg" alt="image" />
              </div>
              <div className="center-img position-absolute">
                <img src="./assets/images/favicons/android-chrome-512x512.png" alt="center-image" />
              </div>
            </div>
            <div className="info-area wow slideInRight" data-wow-delay="250ms">
              <div className="heading">
                <h4>Account Information</h4>
              </div>
              <ul>
                <li><span>A/c Holder</span> <span>Karunyanidhi</span></li>
                <li><span>A/c Number</span> <span>086705300000070</span></li>
                <li><span>IFSC Code</span> <span>SIBL0000867</span></li>
                <li><span>Bank</span> <span>South Indian Bank</span></li>
                <li><span>Branch</span> <span>Mancherial</span></li>
              </ul>
              <div className="upi-area">
                <h5>Scan And Pay Using Any UPI App</h5>
                <div className="upi">
                  <img src="./assets/images/resources/upi.jpg" alt="upi" />
                </div>
              </div>
            </div> 
          </div>
        </div>
      </section>                 {/*Donate Page End*/}
                </div>
        );
    }
}

export default Donate