import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import DatePicker from 'react-datetime';
import moment from 'moment';
import "react-datetime/css/react-datetime.css";
import axios from 'axios';


const TITLE = 'Event || Karunyanidhi'
export class AddEvent extends Component {
    static displayName = AddEvent.name;
    constructor(props) {
        super(props);
        this.state = {
            dt : 'Event Date',
            HomeFile : ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(date) {
        this.setState({
          dt: date
        })
      }

      async submit(e) {    
        e.preventDefault();    
        const url = `https://localhost:44321/api/event/events`;    
        const formData = new FormData(e.target);    
        formData.append('body', this.state.HomeFile);    
        const config = {    
                headers: {    
                        'content-type': 'multipart/form-data',    
                },    
        };    
        return axios.post(url, formData);
}    
setFile(e) {  
    // const test = e.target.files[0];  
    // if (test.size > 1024)
    // alert("File size cannot exceed more than 1MB");
    // else
        this.setState({ HomeFile: e.target.files[0] });    
}    

    async handleRazorpayPayment(e) {
        e.preventDefault();
        const data = new FormData(e.target);
        const email = document.getElementById('email').value;
        const mobile = document.getElementById('mobile').value;
        await axios.post('https://api.karunyanidhi.org/api/payment/initialize', data,
            {
                headers: { 'Access-Control-Allow-Origin': true }
            }).then(res => {
                window.loader();
                const options = {
                    key: 'rzp_test_fuvkKJZqahJhLD',
                    amount: res.data.amount,
                    name: 'Karunyanidhi',
                    description: 'Diocese of Adilabad',
                    image: 'https://karunyanidhi.org/assets/images/favicons/android-chrome-512x512.png',
                    order_id: res.data.id,
                    handler: (response) => {
                        axios.post('https://api.karunyanidhi.org/api/Payment/confirm', response,
                            {
                                headers: { 'Access-Control-Allow-Origin': true }
                            })
                            .then(response => window.location = '/PaymentSuccess')
                            .catch((err) => console.log(err))
                    },
                    prefill: {
                        name: "FJ",
                        email: email,
                        contact: mobile
                    },
                    theme: {
                        color: '#F37254'
                    }
                };
                const rzp1 = new window.Razorpay(options);
                rzp1.open();
            });
    };


    render() {
        return (
            <div>
                <div id="loader" style={{ display: "none" }}></div>
                <Helmet>
                    <title>{TITLE}</title>
                </Helmet>

                {/*donate Page Start*/}
                <section className="donate-page">
                    <div className="container">
                        <div className="block-title text-center">
                            <h4>Add Event</h4>
                            {/* <h2>We’re Helping People in Need</h2> */}
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="donate-form">
                                    {/* <form onSubmit={this.handleRazorpayPayment} className="donate-form-validated donate-one__form"> */}
                                    <form className="donate-form-validated donate-one__form" onSubmit={e => this.submit(e)}>
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <label for="EventName" class="control-label">Event Name</label>
                                                <div className="donate-form__input-box">
                                                    <input type="text" placeholder="Event Name" name="EventName" required />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                            <label for="EventDate" class="control-label">Event Date</label>
                                                <div className="donate-form__input-box">                                                    
                                                    <DatePicker
                                                        value={this.state.dt}
                                                        onChange={this.handleChange}
                                                        timeFormat={false}
                                                        name = "EventDate"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xl-12">
                                            <label for="EventDescription" class="control-label">Event Description</label>
                                                <div className="donate-form__input-box">
                                                    <textarea placeholder="Event Description" name="EventDesc" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                            <label for="HomeImage" class="control-label">Select Home Page Image</label>
                                                <div className="donate-form__input-box">
                                                    <input type="file" placeholder="Home Page Image" onChange={e => this.setFile(e)} />
                                                </div>
                                            </div>
                                            {/* <div className="col-xl-12">
                                            <label for="HomeImage" class="control-label">Select Image1</label>
                                                <div className="donate-form__input-box">
                                                    <input type="file" name="Image1" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                            <label for="HomeImage" class="control-label">Select Image2</label>
                                                <div className="donate-form__input-box">
                                                    <input type="file" name="Image2" />
                                                </div>
                                            </div>
                                            <div className="col-xl-12">
                                            <label for="HomeImage" class="control-label">Select Image3</label>
                                                <div className="donate-form__input-box">
                                                    <input type="file" name="Image3" />
                                                </div>
                                            </div> */}
                                        </div>
                                        
                                        <div className="row">
                                            <div className="col-xl-12">
                                                <button type="submit" className="thm-btn donate-form__btn" >
                                                    Donate
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/*Donate Page End*/}
            </div>
        );
    }
}

export default AddEvent