import React, { Component } from 'react'
export class Header extends Component {
    render () {
        return (       
            <div>  
                <div className="site-header__header-one-wrap clearfix">
                    <div className="container">
                        <div className="site-header__logo-box float-left hidden-sm-down">
                            <div className="site-header__logo">
                                <a href="/">
                                    <img src="assets/images/resources/logo.png" alt="" />
                                </a>
                            </div>
                        </div>
                        <header className="main-nav__header-one">
                            <div className="main-nav__header-one__top clearfix">
                                <div className="main-nav__header-one__top-left">
                                    <ul className="list-unstyled">
                                        <li>
                                            <div className="icon">
                                                <i className="fas fa-phone-square-alt" />
                                            </div>
                                            <div className="text">
                                                <p>
                                                    <a href="tel:+91 6305144737">+91 6305144737</a>
                                                </p>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="icon">
                                                <i className="fas fa-envelope" />
                                            </div>
                                            <div className="text">
                                                <p>
                                                    <a href="mailto:karunyanidhi.adb@gmail.com">
                                                        karunyanidhi.org@gmail.com
                    </a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="main-nav__header-one__top-right">
                                    <div className="main-nav__header-one__top-social">
                                        <a href="#">
                                            <i className="fab fa-facebook-square" />
                                        </a>
                                        <a href="#">
                                            <i className="fab fa-google" />
                                        </a>
                                        <a href="#">
                                            <i className="fab fa-instagram" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <nav className="header-navigation stricky">
                                <div className="container clearfix">
                                    {/* Brand and toggle get grouped for better mobile display */}                                    
                                    <div className="main-nav__left main-nav__left-one float-left">
                                        {/* <a href="#" className="side-menu__toggler">
                                            <i className="fa fa-bars" />
                                        </a> */}
                                        <div className="main-nav__main-navigation clearfix">
                                            <ul className=" main-nav__navigation-box float-left">
                                                <li className="current">
                                                    <a href="/">Home</a>
                                                </li>
                                                <li>
                                                    <a href="#">About Us</a>
                                                </li>
                                                <li>
                                                    <a href="#">Donations</a>
                                                </li>
                                                <li>
                                                    <a href="#">Events</a>
                                                </li>
                                                <li>
                                                    <a href="#">News</a>
                                                </li>
                                                <li>
                                                    <a href="#">Contact</a>
                                                </li>
                                            </ul>
                                        </div>
                                        {/* /.navbar-collapse */}
                                    </div>
                                    <div className="site-header__logo-box float-left hidden-sm-up">
                                        <div className="site-header__logo">
                                            <a href="/">
                                                <img src="assets/images/resources/logo.png" alt="" />
                                            </a>
                                        </div>
                                    </div>
                                    <div className="main-nav__right main-nav__right-one float-right">
                                        <div className="main-nav__right__btn-one">
                                            <a href="/donate">
                                                <i className="fas fa-heart" />
                  Donate
                </a>
                

                                        </div>
                                    </div>
                                </div>
                            </nav>
                        </header>
                    </div>
                </div>    
                <header className="header navbar-fixed-top mobile-menu">
                {/* Navbar */}
                <nav className="navbar" role="navigation">
                  <div className="container">
                    {/* Brand and toggle get grouped for better mobile display */}
                    <div className="menu-container js_nav-item">
                    </div>
                    {/* HAMBURGUER MENU ICON */}
                    <input type="checkbox" name="toggle" id="toggle" />
                    <label htmlFor="navbar-toggle" />
                    {/* Collect the nav links, forms, and other content for toggling */}
                    <div className="menu-mobile">
                      <div className="collapse navbar-collapse nav-collapse ">
                        <div className="menu-container">
                          <ul className="nav navbar-nav container-right ">
                            <li className="js_nav-item nav-item"><a className="nav-item-child" href="/">Home</a></li>
                            <li className="js_nav-item nav-item"><a className="nav-item-child" href="#">About Us</a></li>
                            <li className="js_nav-item nav-item"><a className="nav-item-child" href="#">Donations</a></li>
                            <li className="js_nav-item nav-item"><a className="nav-item-child" href="#">Contact</a></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </nav>
              </header>  
              </div>     
        );
        }   
}

export default Header